.selection-area {
    background: rgba(140, 82, 255, 0.15);
    border: 2px solid rgba(140, 82, 255, 0.81);
    border-radius: 0.1em;
}

.select-container {
    user-select: none;
    cursor: cell;
}

.selectable {
    touch-action: none;
}

.blocked {
    touch-action: auto !important;
}

.block_underline {
    text-decoration: none;
}

body {
    /* Override chakra to use telegram */
    /* background: var(--tg-theme-bg-color, "unset"); */
}

.override-button-color {
    background: var(--tg-theme-button-color) !important;
    color: var(--tg-theme-button-text-color) !important;
}
